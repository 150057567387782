// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-gatsby-theme-tailwindui-templates-page-js": () => import("./../../../src/gatsby-theme-tailwindui/templates/page.js" /* webpackChunkName: "component---src-gatsby-theme-tailwindui-templates-page-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-insight-letters-index-js": () => import("./../../../src/pages/insight-letters/index.js" /* webpackChunkName: "component---src-pages-insight-letters-index-js" */),
  "component---src-pages-insight-letters-sanity-emails-slug-current-js": () => import("./../../../src/pages/insight-letters/{SanityEmails.slug__current}.js" /* webpackChunkName: "component---src-pages-insight-letters-sanity-emails-slug-current-js" */),
  "component---src-pages-investments-sanity-post-slug-current-js": () => import("./../../../src/pages/investments/{SanityPost.slug__current}.js" /* webpackChunkName: "component---src-pages-investments-sanity-post-slug-current-js" */),
  "component---src-pages-investor-js": () => import("./../../../src/pages/investor.js" /* webpackChunkName: "component---src-pages-investor-js" */),
  "component---src-pages-lcof-fund-overview-js": () => import("./../../../src/pages/LCOF-Fund-Overview.js" /* webpackChunkName: "component---src-pages-lcof-fund-overview-js" */),
  "component---src-templates-all-quotes-js": () => import("./../../../src/templates/all-quotes.js" /* webpackChunkName: "component---src-templates-all-quotes-js" */),
  "component---src-templates-pdf-file-js": () => import("./../../../src/templates/pdf-file.js" /* webpackChunkName: "component---src-templates-pdf-file-js" */),
  "component---src-templates-quotes-by-category-js": () => import("./../../../src/templates/quotes-by-category.js" /* webpackChunkName: "component---src-templates-quotes-by-category-js" */),
  "component---src-templates-search-quotes-js": () => import("./../../../src/templates/search-quotes.js" /* webpackChunkName: "component---src-templates-search-quotes-js" */),
  "component---src-templates-single-quote-js": () => import("./../../../src/templates/single-quote.js" /* webpackChunkName: "component---src-templates-single-quote-js" */)
}

